<template>
  <div class="flex flex-col contact_us width-full h-full bg-white justify-items-center pb-10">
    <div>
      <h1 class="font-bold text-2xl lg:text-5xl py-10">ติดต่อเรา</h1>
    </div>
    <div class="grid self-center pb-10">
      <img :src="image" :alt="image" />
    </div>
    <ContactButton />
  </div>
</template>

<script>
import ContactButton from "@/components/ContactButton.vue";

export default {
  components: {
    ContactButton,
  },
  props: {
    image: {
      type: [String],
      default:
        "https://firebasestorage.googleapis.com/v0/b/rajpraditauto.appspot.com/o/files%2Fmap20210901101712.jpg?alt=media&token=4c5dd49d-f458-4a85-bde0-8ed22187d118",
    },
  },
};
</script>
